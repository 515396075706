const convertToDate = dateString => {
  const [day, month, year] = dateString.split("/").map(Number);
  return new Date(year, month - 1, day);
};

const initDates = type => {
  const ELEMENTS = {
    fd: type === 'search' ? 'fd' : 'quote_fd',
    td: type === 'search' ? 'td' : 'quote_td',
  }
  const MIN_DAYS = 5;

  const fd = document.getElementById(ELEMENTS.fd);
  const td = document.getElementById(ELEMENTS.td);

  // Get fd date value
  const today = new Date();
  // Add 1 day to today
  today.setDate(today.getDate() + 1);
  const fdValue = fd.value;
  const tdValue = td.value;

  const date = fdValue ? convertToDate(fdValue) : today;
  const minDate = new Date(today.getTime());
  const maxDate = new Date(date.getTime());

  // minDate.setDate(date.getDate());
  maxDate.setFullYear(date.getFullYear() + 1);

  // Set min date for Td
  const dateTd = new Date(fdValue ? convertToDate(fdValue).getTime() : today.getTime());
  const minDateTd = dateTd.setDate(dateTd.getDate() + MIN_DAYS);


  new Datepicker(fd, {
    format: 'dd/mm/yyyy',
    autohide: true,
    minDate,
    maxDate,
  });

  const datePickerTd = new Datepicker(td, {
    format: 'dd/mm/yyyy',
    autohide: true,
    minDate: minDateTd,
    maxDate,
  });

  const changeDateHandler = ({ detail }) => {
    const date = new Date(detail.date);
    const newDate = new Date(date.getTime());
    const minDate = new Date(date.getTime());
    const maxDate = new Date(date.getTime());

    newDate.setDate(date.getDate() + MIN_DAYS)
    minDate.setDate(date.getDate() + MIN_DAYS);
    maxDate.setFullYear(date.getFullYear() + 1);

    datePickerTd.setDate(newDate);
    datePickerTd.setOptions({
      minDate,
      maxDate
    })
  }

  fd.addEventListener('changeDate', changeDateHandler)
}
const modals = document.querySelectorAll('.mmodal');
const modal = modals[0];
const modal2 = modals[1];
if (modals.length > 0) {
  modals.forEach(modal => {
    const modalCloseButton = modal.querySelector('.mmodal-header__close');
    const modalFooerButtonClose = modal.querySelector('.mmodal-footer__close');

    modalCloseButton.addEventListener('click', () => {
      modal.style.display = 'none';
    });
    modalCloseButton.addEventListener('touchend', () => {
      modal.style.display = 'none';
    });

    modalFooerButtonClose.addEventListener('click', () => {
      modal.style.display = 'none';
    });
    modalFooerButtonClose.addEventListener('touchend', () => {
      modal.style.display = 'none';
    });
  });
}


  // Functions to open and close a modal
  function openModal($el) {
    $el.classList.add('is-active');
  }

  function closeModal($el) {
    $el.classList.remove('is-active');
    // Check if $el id is 'modal-location-info' and if is true set local storage item with key 'locationInfoDisplayed' to true
    if ($el.id === 'modal-location-info') {
      localStorage.setItem('locationInfoDisplayed', 'true');
    }
  }

  function closeAllModals() {
    (document.querySelectorAll('.modal') || []).forEach(($modal) => {
      closeModal($modal);
    });
  }

  // Add a click event on buttons to open a specific modal
  (document.querySelectorAll('.modal-trigger') || []).forEach(($trigger) => {
    const modal = $trigger.dataset.target;
    const $target = document.getElementById(modal);

    $trigger.addEventListener('click', (event) => {
      event.preventDefault();
      openModal($target);
    });
  });

  // Add a click event on various child elements to close the parent modal
  (document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || []).forEach(($close) => {
    const $target = $close.closest('.modal');

    $close.addEventListener('click', () => {
      closeModal($target);
    });
  });

  // Add a keyboard event to close all modals
  document.addEventListener('keydown', (event) => {
    const e = event || window.event;

    if (e.keyCode === 27) { // Escape key
      closeAllModals();
    }
  });
document.addEventListener('DOMContentLoaded', () => {
  // new Mmenu( "#mobile-menu", {
  //   slidingSubmenus: true,
  //   offCanvas: {
  //     page: {
  //       selector: '#autocreta-page',
  //       position: 'left-fromt',
  //     }
  //   },
  //   theme: 'dark'
  // });
  
  const menu = new MmenuLight(
    document.querySelector( "#mobile-menu" )
  );

  const navigator = menu.navigation();
  const drawer = menu.offcanvas();

  document.querySelector( 'a[href="#mobile-menu"]' )
    .addEventListener( 'click', ( evnt ) => {
        evnt.preventDefault();
        drawer.open();
    });

  // Main navigation hover
  // if ($('.navbar-nav').length > 0) {
  //   $('.navbar-nav .dropdown').hover(
  //     function(){
  //       $(this).addClass("open");
  //       $(this).children('.dropdown-menu').hide().stop(true,true).slideDown(300);
  //     },function() {
  //       $(this).removeClass("open");
  //       $(this).children('.dropdown-menu').stop(true,true).slideUp(300);
  //     }
  //   );
    
  //   // Redirection to nav drop menu
  //   $('.nav-link').bind('click', function() {
  //     window.location = $(this).attr('href')
  //   })
  //   $('.nav-drop-menu a').bind('click', function(){
  //     window.location = $(this).attr('href');
  //   });
  // }
});


// document.addEventListener('DOMContentLoaded', () => {
//   // Read local storage item with key 'locationInfoDisplayed' and if is false add class 'is-active' to modal with id 'modal-location-info'
//   if (localStorage.getItem('locationInfoDisplayed') === 'false' || localStorage.getItem('locationInfoDisplayed') === null) {
//     document.getElementById('modal-location-info').classList.add('is-active');
//   }
// });
